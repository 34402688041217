<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col>
          <FilterReport
            :medicalScreening="medicalScreening"
            :generalScreening="generalScreening"
            :isArrivalDateDisabled="isArrivalDateDisabled"
            :transferCenterFilter="transferCenterFilter"
            :isGraduationDateShown="graduationFilter"
            :tradeFilter="tradeFilter"
            @filtering="isFiltered = true"
          ></FilterReport>
        </v-col>
        <v-spacer />
        <div>
          <v-spacer />
          <v-row d-flex justify-end class="py-1">
            <v-btn :to="{ name: 'Reports' }" append text
              ><v-icon>mdi-arrow-left-bold-circle-outline</v-icon>Go Back</v-btn
            >
          </v-row>
          <v-row d-flex justify-end>
            <download-excel
              class="btn"
              :fetch="exportAllDelinquents"
              :before-generate="beforeStart"
              :before-finish="beforeFinish"
              :fields="fieldToExport"
              :name="fileName"
            >
              <div v-if="disabled">
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="primary"
                ></v-progress-circular>
                downloading...
              </div>
              <div v-else>
                <v-btn text color="primary"
                  ><v-icon>mdi-file-excel</v-icon> Excell Export</v-btn
                >
              </div>
            </download-excel>
          </v-row>
        </div>
      </v-row>
    </v-container>
    <v-container>
      <screening-table v-if="screening" :isFiltered="isFiltered"></screening-table>
      <apprehention v-else-if="PlaceOfApprehension" :isFiltered="isFiltered" :state="state"></apprehention>
      <report-table v-else :isFiltered="isFiltered">
        <template v-slot:header>
          <thead v-if="transferedTable">
            <tr>
              <th>ID</th>
              <th>Names</th>
              <th>Age</th>
              <th>Gender</th>
              <th>NID</th>
              <th>marital Status</th>
              <th>Destinations</th>
            </tr>
          </thead>
          <thead v-else-if="tradeTable">
            <tr>
              <th>ID</th>
              <th>Names</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Reason for rehabilitation</th>
              <th>Trade</th>
              <th>Start date</th>
              <th>End dat</th>
            </tr>
          </thead>
          <thead v-else-if="graduationTable">
            <tr>
              <th>ID</th>
              <th>Names</th>
              <th>'Year of birth</th>
              <th>Gender</th>
              <th>Admission date</th>
              <th>Trade</th>
              <th>Graduation date</th>
              <th>Occupation</th>
            </tr>
          </thead>
          <thead v-else-if="postGraduationTable">
            <tr>
              <th>ID</th>
              <th>Names</th>
              <th>'Year of birth</th>
              <th>Gender</th>
              <th>Graduation date</th>
              <th>Occupation</th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="items">
          <tbody v-if="transferedTable">
            <tr v-for="(item, i) in items.items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.age }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.nid }}</td>
              <td>{{ item.maritalStatus }}</td>
              <td>{{ item.transferDestination }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="tradeTable">
            <tr v-for="(item, i) in items.items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.age }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.deviantAct }}</td>
              <td>{{ item.training }}</td>
              <td>{{ item.trainingStartDate }}</td>
              <td>{{ item.trainingEndDate }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="graduationTable">
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.dateOfBirth }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.arrivalDate }}</td>
              <td>{{ item.training }}</td>
              <td>{{ item.graduationDate }}</td>
              <td>{{ item.occupation }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="postGraduationTable">
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.dateOfBirth }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.graduationDate }}</td>
              <td>{{ item.occupation }}</td>
            </tr>
          </tbody>
        </template>
      </report-table>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import downloadExcel from 'vue-json-excel'
import FilterReport from '../../components/FilterReportsComponent'
import screeningTable from '../../components/ScreeningTableReportComponent'
import apprehention from '../../components/PlaceOfApprehension'
import reportTable from '@/components/ReportsTableComponent'
export default {
  components: {
    downloadExcel,
    FilterReport,
    screeningTable,
    apprehention,
    reportTable
  },
  data () {
    return {
      state: null,
      disabled: false,
      screening: false,
      generalScreening: false,
      medicalScreening: false,
      isArrivalDateDisabled: false,
      PlaceOfApprehension: false,
      transferCenterFilter: false,
      fieldToExport: {},
      defaultFieldToExport: {
        Identification: 'nid',
        Names: 'name',
        Age: 'age',
        Gender: 'gender',
        'Marital Status': 'maritalStatus',
        'Mother name': 'motherName',
        'Mother Vital Status': 'motherVitalStatus',
        'Father name': 'fatherName',
        'Father Vital Status': 'fatherVitalStatus',
        'Guardian names': 'guardianName',
        'Guardian contact': 'guardianContact',
        'Province of birth': 'birthProvince',
        'District of birth': 'birthDistrict',
        'Sector of birth': 'birthSector',
        'Cell of birth': 'birthCell',
        'Village of birth': 'birthVillage',
        'District of apprehension': 'apprehensionDistrict',
        'Sector of apprehension': 'apprehensionSector',
        'Cell of apprehension': 'apprehensionCell',
        'Village of apprehension': 'apprehensionVillage',
        'Apprehender Names': 'apprehenderName',
        'Apprehender Contact': 'apprehenderContact',
        'Deviant Acts': 'deviantAct',
        Recidivist: 'recidivist',
        isHomeless: 'homeless',
        'Education level': 'educationLevel',
        'Arrival date': 'arrivalDate'
      },
      transferedTable: false,
      tradeTable: false,
      graduationTable: false,
      postGraduationTable: false,
      graduationFilter: false,
      tradeFilter: false,
      fileName: 'Dmis_export',
      isFiltered: false
    }
  },
  computed: {
    ...mapGetters('reports', ['delinquents']),
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },
  mounted () {
    // this.getDelinquents()
    // console.log(this.$route.params)
    this.toogleViews()
  },
  methods: {
    ...mapActions('reports', ['readDelinquents']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    toogleViews () {
      if (
        this.$route.params.reportId === '0b51a8e7-37cc-499c-8674-b4e10f4f27b8'
      ) {
        this.state = null
        this.fileName = 'List_of_delinquents_by_medical_screening_decision'
        this.screening = true
        this.medicalScreening = true
        this.fieldToExport = {
          ...this.defaultFieldToExport,
          'General screening date': 'generalScreeningDate',
          'General Screening Decision': 'generalScreeningDecision',
          'Medical Screening Decision': 'transferEligibilityState'
        }
      } else if (
        this.$route.params.reportId === 'c314a4b8-8016-4056-ad46-9b11b90e7f63'
      ) {
        this.state = null
        this.generalScreening = true
        this.fileName = 'List_of_delinquents_by_general_screening_decision'
        this.screening = true
        this.fieldToExport = {
          ...this.defaultFieldToExport,
          'General screening date': 'generalScreeningDate',
          'General Screening Decision': 'generalScreeningDecision'
        }
      } else if (
        this.$route.params.reportId === '64abb2b8-997f-48d4-9e5a-2af34cdcfa13'
      ) {
        this.state = null
        this.fileName = 'Dmis_list_of_delinquent'
        this.PlaceOfApprehension = true
        this.fieldToExport = { ...this.defaultFieldToExport }
      } else if (
        this.$route.params.reportId ===
          '6997889f-eb4e-4e19-8dd1-0e0502e804d4') {
        this.state = 'ESCAPED'
        this.fileName = 'Dmis_list_of_delinquent'
        this.PlaceOfApprehension = true
        this.fieldToExport = { ...this.defaultFieldToExport }
      } else if (this.$route.params.reportId ===
          'ce341327-ce06-4a8b-9e22-a3363061eed8') {
        this.state = 'REUNIFIED'
        this.fileName = 'Dmis_list_of_delinquent'
        this.PlaceOfApprehension = true
        this.fieldToExport = { ...this.defaultFieldToExport }
      } else if (
        this.$route.params.reportId === '00513e31-e401-4152-a3ac-e9fd38804fea'
      ) {
        this.state = null
        this.fileName = 'List_of_delinquents_for_graduation'
        this.graduationTable = true
        this.graduationFilter = true
        this.fieldToExport = {
          Identification: 'nid',
          Names: 'name',
          Age: 'age',
          Gender: 'gender',
          'Marital Status': 'maritalStatus',
          'Mother name': 'motherName',
          'Mother Vital Status': 'motherVitalStatus',
          'Father name': 'fatherName',
          'Father Vital Status': 'fatherVitalStatus',
          'District of birth': 'birthDistrict',
          isHomeless: 'homeless',
          'Education level': '',
          'Arrival date': 'arrivalDate',
          'Graduation date': 'graduationDate',
          Intake: 'intake',
          Trade: 'training',
          'Total Score': 'totalScore'
        }
      } else if (
        this.$route.params.reportId === 'aff70f31-225c-4b01-8643-90a0b618669a'
      ) {
        this.state = null
        this.transferCenterFilter = true
        this.fileName = 'List_of_transferred_delinquents'
        this.fieldToExport = {
          ...this.defaultFieldToExport,
          Destinations: 'transferDestination'
        }
        this.transferedTable = true
      } else if (
        this.$route.params.reportId === '34b9ff0f-0b43-49d8-8ba3-a6e05e180e66'
      ) {
        this.state = null
        this.isArrivalDateDisabled = true
        this.tradeFilter = true
        this.fileName = 'List_of_delinquents_by_trade'
        this.fieldToExport = {
          Identification: 'nid',
          Names: 'name',
          Age: 'age',
          Gender: 'gender',
          'Marital Status': '',
          'Education level': '',
          'Arrival date': '',
          Intake: 'intake',
          Trade: 'training',
          'Start date': 'trainingStartDate',
          'End date': 'trainingEndDate'
        }
        this.tradeTable = true
      } else if (
        this.$route.params.reportId === 'fcf6580d-6491-488c-bc46-599e27621fdb'
      ) {
        this.state = null
        this.graduationFilter = true
        this.fileName = 'Post_graduation_follow-up'
        this.fieldToExport = {
          Identification: 'nid',
          Names: 'name',
          Age: 'age',
          Gender: 'gender',
          'Marital Status': 'maritalStatus',
          'Mother name': 'motherName',
          'Mother Vital Status': 'motherVitalStatus',
          'Father name': 'fatherName',
          'Father Vital Status': 'fatherVitalStatus',
          'District of birth': 'birthDistrict',
          isHomeless: 'homeless',
          'Education level': '',
          'Arrival date': 'arrivalDate',
          'Graduation date': 'graduationDate',
          Intake: 'intake',
          Training: 'training',
          'Total Score': 'totalScore'
        }
        this.postGraduationTable = true
      }
    },
    getDelinquents () {
      this.$reportService
        .getReport({
          reportId: this.$route.params.reportId,
          params: {
            paged: false,
            states: this.state
          }
        })
        .then((res) => {
          this.readDelinquents(res.content)
        })
    },
    async exportAllDelinquents () {
      const response = await this.$reportService.getReport({
        reportId: this.$route.params.reportId,
        params: { paged: false, state: this.state }
      })
      return response.content
    },
    beforeStart () {
      this.disabled = true
    },
    beforeFinish () {
      this.disabled = false
    }
  }
}
</script>

<style scoped>
/* tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

td {
  vertical-align: top;
} */

/* table { border-top: 1px solid #dddddd; } */
table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
/* @media screen and (max-width: 700px) {
   .theme--light.v-data-table thead tr:last-child th,
   .theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
   .theme--light.v-data-table tbody tr td,
   .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
      border-bottom: medium solid rgba(0,0,0,.12);
   }
} */
</style>
