<template>
  <div>
    <v-container>
      <v-data-table
        :items="isFiltered ? delinquents : elements"
        class="elevation-1"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="elementsPerPage"
        :server-items-length="totalElements"
        no-data-text="NO DATA HERE!"
        hide-default-footer
      >
        <template v-slot:header>
          <slot name="header" />
        </template>
        <template v-if="!isEmpty" v-slot:body="{ items }">
          <slot name="body" :items="items" />
        </template>
      </v-data-table>
    </v-container>
    <v-container v-if="!isFiltered">
      <div class="pt-2 px-12">
        <v-row>
          <v-col cols="12" sm="2">
            <v-select
              :items="selectElementsPerPage"
              v-model="elementsPerPage"
              label="Elements per page"
              dense
              filled
            ></v-select>
          </v-col>
          <v-col cols="12" sm="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              circle
              total-visible="7"
              color="blue"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  props: {
    isFiltered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      elements: [],
      isEmpty: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'Names', value: 'names', sortable: false },
        { text: 'Year of birth', value: 'dateOfBirth', sortable: false },
        { text: 'Gender', value: 'gender', sortable: false },
        { text: 'Graduate date', value: 'graduationDate', sortable: false },
        {
          text: 'Occupation',
          value: 'occupation',
          sortable: false
        }
      ],
      selectElementsPerPage: [5, 10, 15, 20, 25, 30],
      totalElements: 0,
      page: 1,
      pageCount: 0,
      elementsPerPage: 20
    }
  },
  computed: {
    ...mapGetters('reports', ['delinquents']),
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },
  watch: {
    page: {
      handler () {
        this.getDelinquents()
      }
    },
    elementsPerPage: {
      handler () {
        this.page = 1
        this.getDelinquents()
      }
    }
  },
  mounted () {
    this.getDelinquents()
  },
  methods: {
    ...mapActions('reports', ['readDelinquents']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    getDelinquents () {
      this.$reportService
        .getReport({
          reportId: this.$route.params.reportId,
          params: {
            page: this.page - 1,
            size: this.elementsPerPage
          }
        })
        .then((res) => {
          this.totalElements = res.totalElements
          if (res.content.length === 0) {
            this.isEmpty = true
          }
          this.elements = res.content
          // this.readDelinquents(res.content)
        })
    }
  }
}
</script>

<style>
</style>
