<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="10">
            <v-row v-if="!isArrivalDateDisabled">
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      :label="isGraduationDateShown ? 'Graduation Date': 'Arrival Date'"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :hint="isGraduationDateShown ? 'Graduation Date':' Arrival Date'"
                      dense
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="fromDate"
                    @change="fromDateSave"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="toDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :hint="isGraduationDateShown ? 'Graduation Date Date interval': 'Arrival Date interval'"
                      :disabled="toDateDisabled"
                      dense
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="toDate"
                    @change="toDateSave"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="tradeFilter">
              <v-col>
                <v-select
                  :items="trainings"
                  item-text="name"
                  item-value="id"
                  dense
                  label="Select Trade"
                  outlined
                  v-model="training"
                  :loading="trainings.length === 0"
                />
              </v-col>
            </v-row>
            <v-row v-if="generalScreening">
              <v-col>
                <v-select
                  v-model="decisionIds"
                  :items="screeningDecisions"
                  item-text="name"
                  item-value="id"
                  label="Screening decision"
                  :loading="screeningDecisions.length === 0"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="medicalScreening">
              <v-col>
                <v-select
                  v-model="eligibilityStates"
                  :items="medicalDecisions"
                  label="Screening decision"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="transferCenterFilter">
              <v-col>
                <v-select
                  v-model="transferCenterId"
                  :items="destinations"
                  item-text="name"
                  item-value="id"
                  label="Destination"
                  :loading="destinations.length === 0"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="mt-8"
              elevation="1"
              fab
              small
              @click="searchDelinquentReport()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    generalScreening: {
      type: Boolean,
      default: false
    },
    medicalScreening: {
      type: Boolean,
      default: false
    },
    isArrivalDateDisabled: {
      type: Boolean,
      default: false
    },
    isGraduationDateShown: {
      type: Boolean,
      default: false
    },
    transferCenterFilter: {
      type: Boolean,
      default: false
    },
    tradeFilter: {
      type: Boolean,
      default: false
    },
    isFiltered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      toDateDisabled: true,
      arrivalDates: [],
      graduationDates: [],
      // generalScreening: false,
      // medicalScreening: false,
      fromDate: null,
      fromDateMenu: false,
      toDate: null,
      toDateMenu: false,
      decisionIds: '',
      screeningDecisions: [],
      trainings: [],
      training: null,
      eligibilityStates: '',
      medicalDecisions: ['ELIGIBLE', 'NOTELIGIBLE', 'UNKNOWN'],
      transferCenterId: null,
      destinations: []
    }
  },
  mounted () {
    this.getDecisions()
    this.getTrainings()
    // this.toogleViews()
  },
  watch: {
    transferCenterFilter: {
      handler () {
        if (this.transferCenterFilter) {
          this.getCenters()
        }
      }
    }
  },
  methods: {
    ...mapActions('reports', ['readDelinquents']),
    fromDateSave (date) {
      this.$refs.menu.save(date)
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      if (this.isGraduationDateShown) {
        this.graduationDates[0] = date1
      } else {
        this.arrivalDates[0] = date1
      }
      this.toDateDisabled = false
    },
    toDateSave (date) {
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      // this.$refs.menu.save(date1)
      this.$refs.menu.save(date)
      if (this.isGraduationDateShown) {
        this.graduationDates[1] = date1
      } else {
        this.arrivalDates[1] = date1
      }
    },
    searchDelinquentReport () {
      const params = {
        arrivalDates:
          this.arrivalDates.length > 1
            ? this.arrivalDates
            : this.arrivalDates.length === 1
              ? this.arrivalDates[0]
              : null,
        graduationDates:
          this.graduationDates.length > 1
            ? this.graduationDates
            : this.graduationDates.length === 1
              ? this.graduationDates[0]
              : null,
        decisionIds: this.decisionIds,
        eligibilityStates: this.eligibilityStates,
        transferCenterId: this.transferCenterId,
        trainingId: this.training,
        sort: 'arrivalDate,desc',
        paged: false
      }
      // console.log(params)
      this.$reportService
        .getReport({
          reportId: this.$route.params.reportId,
          params: params
        })
        .then((res) => {
          this.readDelinquents(res.content)
          this.$emit('filtering')
        })
    },
    getDecisions () {
      this.$delinquentService.fetchDecisionsObjects().then((res) => {
        this.screeningDecisions = res.content
      })
    },
    getCenters () {
      this.$centerService.fetchAllAnyParams({ paged: false }).then((res) => {
        this.destinations = res.content
      })
    },
    getTrainings () {
      this.$delinquentService.fetchTrainings()
        .then(response => {
          if (!response.status) {
            this.trainings = response.content
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Trainings: ' + response.message)
          }
        })
    },
    toogleViews () {
      if (
        this.$route.params.reportId === '0b51a8e7-37cc-499c-8674-b4e10f4f27b8'
      ) {
        this.medicalScreening = true
      }
      if (
        this.$route.params.reportId === 'c314a4b8-8016-4056-ad46-9b11b90e7f63'
      ) {
        this.generalScreening = true
      }
    }
  }
}
</script>

<style>
</style>
