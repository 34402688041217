<template>
  <div>
    <v-container>
      <v-data-table
        :items="isFiltered ? delinquents : elements"
        class="elevation-1"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="elementsPerPage"
        :server-items-length="totalElements"
        hide-default-footer
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th>ID</th>
              <th>Names</th>
              <th>Age</th>
              <th>Sex</th>
              <th>NID</th>
              <th>Marital Status</th>
              <th>General Screening Decision</th>
              <th v-if="medicalScreening">Medical Screening Decision</th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.age }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.nid }}</td>
              <td>{{ item.maritalStatus }}</td>
              <td>{{ item.generalScreeningDecision }}</td>
              <td v-if="medicalScreening">
                {{ item.transferEligibilityState }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
    <v-container v-if="!isFiltered">
      <div class="pt-2 px-12">
        <v-row>
          <v-col cols="12" sm="2">
            <v-select
              :items="selectElementsPerPage"
              v-model="elementsPerPage"
              label="Elements per page"
              dense
              filled
            ></v-select>
          </v-col>
          <v-col cols="12" sm="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              circle
              total-visible="7"
              color="blue"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
// import downloadExcel from 'vue-json-excel'
export default {
  props: {
    isFiltered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      elements: [],
      medicalScreening: false,
      selectElementsPerPage: [5, 10, 15, 20, 25, 30],
      totalElements: 0,
      page: 1,
      pageCount: 0,
      elementsPerPage: 20
    }
  },
  watch: {
    page: {
      handler () {
        this.getDelinquents()
      }
    },
    elementsPerPage: {
      handler () {
        this.page = 1
        this.getDelinquents()
      }
    }
  },
  computed: {
    ...mapGetters('reports', ['delinquents']),
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },
  mounted () {
    this.getDelinquents()
    // console.log(this.$route.params)
    this.toogleViews()
  },
  methods: {
    ...mapActions('reports', ['readDelinquents']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    toogleViews () {
      if (
        this.$route.params.reportId === '0b51a8e7-37cc-499c-8674-b4e10f4f27b8'
      ) {
        this.medicalScreening = true
      }
    },
    getDelinquents () {
      this.$reportService
        .getReport({
          reportId: this.$route.params.reportId,
          params: {
            page: this.page - 1,
            size: this.elementsPerPage
          }
        })
        .then((res) => {
          this.totalElements = res.totalElements
          this.elements = res.content
          // this.readDelinquents(res.content)
        })
    }
  }
}
</script>

<style>
</style>
