<template>
  <div>
    <v-container>
      <v-data-table
        :items="isFiltered ? delinquents : elements"
        class="elevation-1"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="elementsPerPage"
        :server-items-length="totalElements"
        hide-default-footer
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th>ID</th>
              <th>Names</th>
              <th>Age</th>
              <th>Sex</th>
              <th>NID</th>
              <th>Marital Status</th>
              <th :colspan="2">Place of Apprehension</th>
              <th v-if="transitCenter">Deviant acts</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>District</th>
              <th>Sector</th>
              <th></th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.age }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.nid }}</td>
              <td>{{ item.maritalStatus }}</td>
              <td>{{ item.apprehensionDistrict }}</td>
              <td>{{ item.apprehensionSector }}</td>
              <td v-if="transitCenter">{{ item.deviantAct }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
    <v-container v-if="!isFiltered">
      <div class="pt-2 px-12">
        <v-row>
          <v-col cols="12" sm="2">
            <v-select
              :items="selectElementsPerPage"
              v-model="elementsPerPage"
              label="Elements per page"
              dense
              filled
            ></v-select>
          </v-col>
          <v-col cols="12" sm="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              circle
              total-visible="7"
              color="blue"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
// import downloadExcel from 'vue-json-excel'
export default {
  props: {
    isFiltered: {
      type: Boolean,
      default: false
    },
    state: null
  },
  data () {
    return {
      elements: [],
      transitCenter: false,
      selectElementsPerPage: [5, 10, 15, 20, 25, 30],
      totalElements: 0,
      page: 1,
      pageCount: 0,
      elementsPerPage: 20
    }
  },
  computed: {
    ...mapGetters('reports', ['delinquents']),
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },
  watch: {
    page: {
      handler () {
        this.getDelinquents()
      }
    },
    elementsPerPage: {
      handler () {
        this.page = 1
        this.getDelinquents()
      }
    }
  },
  mounted () {
    this.getDelinquents()
    // console.log(this.$route.params)
    this.toogleViews()
  },
  methods: {
    ...mapActions('reports', ['readDelinquents']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    toogleViews () {
      if (
        this.$route.params.reportId === '64abb2b8-997f-48d4-9e5a-2af34cdcfa13'
      ) {
        this.transitCenter = true
      }
    },
    getDelinquents () {
      this.$reportService
        .getReport({
          reportId: this.$route.params.reportId,
          params: {
            page: this.page - 1,
            size: this.elementsPerPage,
            states: this.state
          }
        })
        .then((res) => {
          this.totalElements = res.totalElements
          this.elements = res.content
          // this.readDelinquents(res.content)
        })
    }
  }
}
</script>

<style scoped>
/* tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

td {
  vertical-align: top;
} */

/* table { border-top: 1px solid #dddddd; } */
table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
/* @media screen and (max-width: 700px) {
   .theme--light.v-data-table thead tr:last-child th,
   .theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
   .theme--light.v-data-table tbody tr td,
   .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
      border-bottom: medium solid rgba(0,0,0,.12);
   }
} */
</style>
